const defaults = [{
	path: "/",
	name: "Index",
	component: () => import("@/views/index/Index")
}, {
	path: "*",
	name: "Miss",
	component: () => import("@/views/index/Miss")
}];

const best = [{
	path: '/index/bestPeopleList/:id?',
	name: 'BestPeopleList',
	component: () => import("@/views/index/BestPeopleList.vue")
}, {
	path: '/index/bestTeamList/:id?',
	name: 'BestTeamList',
	component: () => import("@/views/index/BestTeamList.vue")
}, {
	path: '/index/bestGroupList/:id?',
	name: 'BestGroupList',
	component: () => import("@/views/index/BestGroupList.vue")
}, {
	path: '/index/bestPeopleDetail/:id?/:total?/:district_id?',
	name: 'BestPeopleDetail',
	component: () => import("@/views/index/BestPeopleDetail.vue")
}];

const other = [{
	path: '/toggle',
	name: 'Toggle',
	component: () => import("@/views/index/Toggle.vue")
}, {
	path: '/system-notice',
	name: 'SystemNotice',
	component: () => import("@/views/index/SystemNotice.vue")
}, {
	path: '/interestDoyin',
	name: 'InterestDoyin',
	component: () => import("@/views/index/InterestDoyin.vue")
}, {
	path: '/insurance',
	name: 'Insurance',
	component: () => import("@/views/index/Insurance.vue")
}];



const activity = [{
	path: '/activity',
	name: 'Activity',
	component: () => import("@/views/activity/Activity.vue")
}, {
	path: '/activity/search/:keywords?',
	name: 'ActivitySearch',
	component: () => import("@/views/activity/ActivitySearch.vue")
}, {
	path: '/activity/detail/:activityId',
	name: 'ActivityDetail',
	component: () => import("@/views/activity/ActivityDetail.vue")
}];

const signIn = [{
		path: '/sign-in/:type?',
		name: 'SignIn',
		component: () => import("@/views/signIn/SignIn.vue")
	},
	{
		path: '/sign-in/detail/:id',
		name: 'SignInDetail',
		component: () => import("@/views/signIn/SignInDetail.vue")
	}
];

const message = [{
		path: '/message',
		name: 'Message',
		component: () => import("@/views/message/Message.vue")
	},
	{
		path: '/message/chat/:id',
		name: 'GroupChat',
		component: () => import("@/views/message/GroupChat.vue")
	},
	{
		path: '/message/user/:id?/:district_id?',
		name: 'GroupChatUser',
		component: () => import("@/views/message/GroupChatUser.vue")
	},
	{
		path: '/message/rank',
		name: 'GroupChatRank',
		component: () => import("@/views/message/GroupChatRank.vue")
	},
	{
		path: '/message/letter/detail/:id',
		name: 'MessageLetterDetail',
		component: () => import("@/views/message/MessageLetterDetail.vue")
	}
];

const news = [{
	path: '/about',
	name: 'About',
	component: () => import("@/views/news/About.vue")
}, {
	path: '/news',
	name: 'News',
	component: () => import("@/views/news/News.vue")
}, {
	path: '/news/details/:id',
	name: 'NewsDetail',
	component: () => import("@/views/news/NewsDetail.vue")
}, {
	path: '/news/articleHonor',
	name: 'ArticleHonor',
	component: () => import("@/views/news/articleHonor.vue")
}, {
	path: '/news/articleTalent',
	name: 'ArticleTalent',
	component: () => import("@/views/news/articleTalent.vue")
}, {
	path: '/news/file/:id',
	name: 'NewsFile',
	component: () => import("@/views/news/NewsFile.vue")
}, {
	path: '/news/newJob',
	name: 'newJob',
	component: () => import("@/views/news/newJob.vue")
}, {
	path: '/news/newJobDetail/:id',
	name: 'newJobDetail',
	component: () => import("@/views/news/newJobDetail.vue")
}, {
	path: '/news/AboutSongDetail/:id',
	name: 'AboutSongDetail',
	component: () => import("@/views/news/AboutSongDetail.vue")
}];

const rank = [{
		path: '/rank',
		name: 'Rank',
		component: () => import("@/views/rank/Department.vue")
	},
	{
		path: '/rank/districtRank',
		name: 'DistrictRank',
		component: () => import("@/views/rank/DistrictRank.vue")
	},
	{
		path: '/rank/dedication',
		name: 'Dedication',
		component: () => import("@/views/rank/Dedication.vue")
	}
];

const user = [{
	path: '/user',
	name: 'User',
	component: () => import("@/views/user/User.vue")
}, {
	path: '/user/collect',
	name: 'UserCollect',
	component: () => import("@/views/user/UserCollect.vue")
}, {
	path: '/user/consumeList',
	name: 'ConsumeList',
	component: () => import("@/views/user/ConsumeList.vue")
}, {
	path: '/user/consumeListDetail/:id',
	name: 'ConsumeListDetail',
	component: () => import("@/views/user/ConsumeListDetail.vue")
}, {
	path: '/user/modifyInfo',
	name: 'ModifyInfo',
	component: () => import("@/views/user/ModifyInfo.vue")
}, {
	path: '/user/level',
	name: 'UserLevel',
	component: () => import("@/views/user/UserLevel.vue")
}, {
	path: '/user/donate',
	name: 'UserDonate',
	component: () => import("@/views/user/UserDonate.vue")
}, {
	path: '/user/exchang',
	name: 'UserExchange',
	component: () => import("@/views/user/UserExchange.vue")
}, {
	path: '/user/finish/shaky',
	name: 'UserFinishShaky',
	component: () => import("@/views/user/UserFinishShaky.vue")
}, {
	path: '/user/info/:id?',
	name: 'UserInfo',
	component: () => import("@/views/user/UserInfo.vue")
}, {
	path: '/user/info/share',
	name: 'UserInfoShare',
	component: () => import("@/views/user/UserInfoShare.vue")
}, {
	path: '/user/description',
	name: 'LevelDescription',
	component: () => import("@/views/user/LevelDescription.vue")
}, {
	path: '/user/enroll/shaky',
	name: 'UserEnrollShaky',
	component: () => import("@/views/user/UserEnrollShaky.vue")
}, {
	path: '/user/apply/vigilante',
	name: 'ApplyVigilante',
	component: () => import("@/views/user/ApplyVigilante.vue")
}, {
	path: '/user/contribute/contribute',
	name: 'Contribute',
	component: () => import("@/views/user/contribute/contribute.vue")
}, {
	path: '/user/contribute/contributeRecord',
	name: 'ContributeRecord',
	component: () => import("@/views/user/contribute/contributeRecord.vue")
}, {
	path: '/user/contribute/contributePay',
	name: 'ContributePay',
	component: () => import("@/views/user/contribute/contributePay.vue")
}, {
	path: '/user/helpCenter',
	name: 'HelpCenter',
	component: () => import("@/views/user/HelpCenter.vue")
}, {
	path: '/user/levelDescriptionShare',
	name: 'LevelDescriptionShare',
	component: () => import("@/views/user/LevelDescriptionShare.vue")
}, {
	path: '/user/audit/auditList',
	name: 'AuditList',
	component: () => import("@/views/user/audit/AuditList.vue")
}, {
	path: '/user/audit/auditDetail/:id?/:type?',
	name: 'AuditDetail',
	component: () => import("@/views/user/audit/AuditDetail.vue")
}, {
	path: '/user/audit/auditDemotion/:id?/:dutyLevel?',
	name: 'AuditDemotion',
	component: () => import("@/views/user/audit/AuditDemotion.vue")
}, {
	path: '/user/audit/auditUserInfo/:id?',
	name: 'AuditUserInfo',
	component: () => import("@/views/user/audit/AuditUserInfo.vue")
}, {
	path: '/user/activityAudit/activityAudit',
	name: 'ActivityAudit',
	component: () => import("@/views/user/activityAudit/ActivityAudit.vue")
}, {
	path: '/user/activityAudit/activityAuditDetail/:id?',
	name: 'ActivityAuditDetail',
	component: () => import("@/views/user/activityAudit/ActivityAuditDetail.vue")
}, {
	path: '/user/audit/xzAuditUserInfo/:id?',
	name: 'XzAuditUserInfo',
	component: () => import("@/views/user/audit/XzAuditUserInfo.vue")
}, {
	path: '/user/audit/xzAuditList',
	name: 'XzAuditList',
	component: () => import("@/views/user/audit/XzAuditList.vue")
}, {
	path: '/user/audit/xzAuditDetail/:id?/:type?',
	name: 'XzAuditDetail',
	component: () => import("@/views/user/audit/XzAuditDetail.vue")
}];


const business = [{
		path: '/business',
		name: 'Business',
		component: () => import("@/views/business/Business.vue")
	},
	{
		path: '/business/create',
		name: 'BusinessCreate',
		component: () => import("@/views/business/BusinessCreate.vue")
	},
	{
		path: '/business/address',
		name: 'BusinessAddress',
		component: () => import("@/views/business/BusinessAddress.vue")
	},
	{
		path: '/business/address/create',
		name: 'BusinessAddressCreate',
		component: () => import("@/views/business/BusinessAddressCreate.vue")
	},
	{
		path: '/business/address/update/:id',
		name: 'BusinessAddressUpdate',
		component: () => import("@/views/business/BusinessAddressUpdate.vue")
	},
	{
		path: '/business/details/:id',
		name: 'BusinessDetails',
		component: () => import("@/views/business/BusinessDetails.vue")
	}
];

const group = [{
		path: '/group',
		name: 'Group',
		component: () => import("@/views/group/Group.vue")
	},
	{
		path: '/group/detail/:id?/:district_id?',
		name: 'GroupDetail',
		component: () => import("@/views/group/GroupDetail.vue")
	},
]

const task = [{
	path: '/task',
	name: 'Task',
	component: () => import("@/views/task/Task.vue")
}, {
	path: '/task/submit/:id',
	name: 'OfflineTaskSubmit',
	component: () => import("@/views/task/OfflineTaskSubmit.vue")
}, {
	path: '/task/detail/:id',
	name: 'OnlineTaskDetail',
	component: () => import("@/views/task/OnlineTaskDetail.vue")
}, {
	path: '/task/rule',
	name: 'IntegralRule',
	component: () => import("@/views/task/IntegralRule.vue")
}];

const transfer = [{
	path: '/transfer',
	name: 'Transfer',
	component: () => import("@/views/transfer/Transfer.vue")
}, {
	path: '/transfer/details/:id',
	name: 'TransferDetails',
	component: () => import("@/views/transfer/TransferDetails.vue")
}, {
	path: '/transfer/list',
	name: 'TransferList',
	component: () => import("@/views/transfer/TransferList.vue")
}];

const register = [{
		path: '/register/:step?',
		name: 'Register',
		component: () => import("@/views/register/Register.vue")
	},
	{
		path: '/register/notice',
		name: 'RegisterNotice',
		component: () => import("@/views/register/RegisterNotice.vue")
	},
	{
		path: '/join',
		name: 'RegisterJoin',
		component: () => import("@/views/register/RegisterJoin.vue")
	},
	{
		path: '/welcome',
		name: 'RegisterWelcome',
		component: () => import("@/views/register/RegisterWelcome.vue")
	}
];

const redeem = [{
	path: '/redeem',
	name: 'Redeem',
	component: () => import("@/views/redeem/Redeem.vue")
}, {
	path: '/redeem/detail/:goodsId',
	name: 'GoodsDetail',
	component: () => import("@/views/redeem/GoodsDetail.vue")
}, {
	path: '/redeem/record',
	name: 'ExchangeRecord',
	component: () => import("@/views/redeem/ExchangeRecord.vue")
}];

const company = [{
		path: '/company',
		name: 'Company',
		component: () => import("@/views/company/Company.vue"),
	},
	{
		path: '/suggest',
		name: 'Suggest',
		component: () => import("@/views/suggest/Suggest.vue"),
	},
	{
		path: '/suggest/list',
		name: 'SuggestList',
		component: () => import("@/views/suggest/SuggestList.vue"),
	},
	{
		path: '/suggest/reply',
		name: 'SuggestReply',
		component: () => import("@/views/suggest/SuggestReply.vue"),
	},
	{
		path: '/blacklist',
		name: 'Blacklist',
		component: () => import("@/views/index/Blacklist.vue"),
	}
]

const study = [{
	path: '/study',
	name: 'Study',
	component: () => import("@/views/study/Study.vue")
}, {
	path: '/study/detail/:id',
	name: 'StudyDetail',
	component: () => import("@/views/study/StudyDetail.vue")
}]

const commodity = [{
	path: '/commodity',
	name: 'Commodity',
	component: () => import("@/views/commodity/Commodity.vue")
}, {
	path: '/commodity/detail/:id',
	name: 'CommodityDetail',
	component: () => import("@/views/commodity/CommodityDetail.vue")
}, {
	path: '/commodity/list/:type?',
	name: 'CommodityList',
	component: () => import("@/views/commodity/CommodityList.vue")
}, {
	path: '/commodity/shopping/:id',
	name: 'CommodityShopping',
	component: () => import("@/views/commodity/CommodityShopping.vue")
}, {
	path: '/commodity/buyNow/:id',
	name: 'CommodityBuyNow',
	component: () => import("@/views/commodity/CommodityBuyNow.vue")
}, {
	path: '/commodity/address',
	name: 'CommodityAddress',
	component: () => import("@/views/commodity/CommodityAddress.vue")
}, {
	path: '/commodity/addressCreate',
	name: 'CommodityAddressCreate',
	component: () => import("@/views/commodity/CommodityAddressCreate.vue")
}, {
	path: '/commodity/addressEdit/:id',
	name: 'CommodityAddressEdit',
	component: () => import("@/views/commodity/CommodityAddressEdit.vue")
}, {
	path: '/commodity/search',
	name: 'CommoditySearch',
	component: () => import("@/views/commodity/CommoditySearch.vue")
}, {
	path: '/commodity/finish/:data?',
	name: 'CommodityFinish',
	component: () => import("@/views/commodity/CommodityFinish.vue")
}, {
	path: '/commodity/merchant/:id?',
	name: 'CommodityMerchant',
	component: () => import("@/views/commodity/CommodityMerchant.vue")
}, {
	path: '/commodity/merchantEdit/:id?',
	name: 'CommodityMerchantEdit',
	component: () => import("@/views/commodity/CommodityMerchantEdit.vue")
}, {
	path: '/commodity/merchantList/:id?',
	name: 'CommodityMerchantList',
	component: () => import("@/views/commodity/CommodityMerchantList.vue")
}];


const circleFriends = [{
	path: '/circleFriends',
	name: 'CircleFriends',
	component: () => import("@/views/circleFriends/CircleFriends.vue")
}, {
	path: '/circleDetail/:id',
	name: 'CircleDetail',
	component: () => import("@/views/circleFriends/CircleDetail.vue")
}, {
	path: '/cirleDetailPraise/:id',
	name: 'CirleDetailPraise',
	component: () => import("@/views/circleFriends/CirleDetailPraise.vue")
}, {
	path: '/addCirle',
	name: 'AddCirle',
	component: () => import("@/views/circleFriends/AddCirle.vue")
}]

const clueReport = [{
	path: '/clueReport',
	name: 'ClueReport',
	component: () => import("@/views/clueReport/ClueReport.vue")
}, {
	path: '/clueType',
	name: 'ClueType',
	component: () => import("@/views/clueReport/ClueType.vue")
}, {
	path: '/clueReportList',
	name: 'ClueReportList',
	component: () => import("@/views/clueReport/ClueReportList.vue")
}, {
	path: '/clueReportInfo',
	name: 'ClueReportInfo',
	component: () => import("@/views/clueReport/ClueReportInfo.vue")
}, {
	path: '/clueSelectPolice',
	name: 'ClueSelectPolice',
	component: () => import("@/views/clueReport/ClueSelectPolice.vue")
}, {
	path: '/clueMap',
	name: 'ClueMap',
	component: () => import("@/views/clueReport/ClueMap.vue")
}]

const supervision = [{
	path: '/supervision',
	name: 'Supervision',
	component: () => import("@/views/supervision/Supervision.vue")
}, {
	path: '/addSupervision',
	name: 'AddSupervision',
	component: () => import("@/views/supervision/AddSupervision.vue")
}, {
	path: '/supervisionType',
	name: 'SupervisionType',
	component: () => import("@/views/supervision/SupervisionType.vue")
}, {
	path: '/supervisionList',
	name: 'SupervisionList',
	component: () => import("@/views/supervision/SupervisionList.vue")
}]

const telecast = [{
	path: '/telecast',
	name: 'Telecast',
	component: () => import("@/views/telecast/Telecast.vue")
}, {
	path: '/calendar',
	name: 'Calendar',
	component: () => import("@/views/telecast/Calendar.vue")
}, {
	path: '/telecastDetail/:id?',
	name: 'TelecastDetail',
	component: () => import("@/views/telecast/TelecastDetail.vue")
}]

const additional = [{
	path: '/additional',
	name: 'Additional',
	component: () => import("@/views/additional/Additional.vue")
}, {
	path: '/additionalList',
	name: 'AdditionalList',
	component: () => import("@/views/additional/AdditionalList.vue")
}]


const merchant = [{
	path: '/merchant/:id?',
	name: 'Merchant',
	component: () => import("@/views/merchant/Merchant.vue")
}, {
	path: '/merchant/detail/:id/:merchantId?',
	name: 'MerchantDetail',
	component: () => import("@/views/merchant/MerchantDetail.vue")
}, {
	path: '/merchant/shipments/:orderId/:productId/:merchantId?',
	name: 'MerchantShipments',
	component: () => import("@/views/merchant/MerchantShipments.vue")
}]

const notice = [{
	path: '/notice/:id/:name',
	name: 'Notice',
	component: () => import("@/views/notice/Notice.vue")
}, {
	path: '/noticeDetail/:detailId',
	name: 'NoticeDetail',
	component: () => import("@/views/notice/NoticeDetail.vue")
}]


const purchase = [{
	path: '/purchase',
	name: 'Purchase',
	component: () => import("@/views/purchase/Purchase.vue")
}, {
	path: '/purchase/detail/:id?',
	name: 'PurchaseDetail',
	component: () => import("@/views/purchase/PurchaseDetail.vue")
}, {
	path: '/purchase/firm/:id?/:type',
	name: 'PurchaseFirm',
	component: () => import("@/views/purchase/PurchaseFirm.vue")
}, {
	path: '/purchase/buy/:id?',
	name: 'PurchaseBuy',
	component: () => import("@/views/purchase/PurchaseBuy.vue")
}]


export default defaults.concat(activity, best, signIn, message, news, rank, other, user, business, group,
	task, transfer, register, redeem, company, study, commodity, circleFriends, clueReport, supervision, telecast,
	additional, merchant, notice, purchase)